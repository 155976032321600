import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import CommonPage from "../Components/CommonPage/CommonPage";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HeaderAccountsPage from "../Components/Header/HeaderAccountsPage";
import About from "../Screens/About/About";
import AccountSettings from "../Screens/Accounts/AccountSettings/AccountSetting";
import BillingDetails from "../Screens/Accounts/AccountSettings/BillingDetails";
import StreamingActivity from "../Screens/Accounts/AccountSettings/StreamingActivity";
import ForgotPassword from "../Screens/Accounts/ForgotPassword/ForgotPassword";
import ResetPassword from "../Screens/Accounts/ForgotPassword/ResetPassword";
import Login from "../Screens/Accounts/Login/Login";
import Register from "../Screens/Accounts/Register/Register";
import ContactSupport from "../Screens/ContactPages/ContactSupport";
import ContactUs from "../Screens/ContactPages/ContactUs";
import Homepage from "../Screens/homepage/Homepage";
import LandingScreeen from "../Screens/LandingScreen/LandingScreeen";
import MyList from "../Screens/MyList/MyList";
import LatestNews from "../Screens/NewsSection/LatestNews";
import NewsDetails from "../Screens/NewsSection/NewsDetails";
import NotFound from "../Screens/NotFound/NotFound";
import GeoBlock from "../Screens/NotFound/GeoBlock";
import PaymentFailed from "../Screens/PaymentStatus/PaymentFailed/PaymetFailed";
import PaymentSuccess from "../Screens/PaymentStatus/PaymentSuccess/PaymentSuccess";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import Search from "../Screens/Search/Search";
import DetailsScreen from "../Screens/ShowDetailsScreen/DetailsScreen";
import Payment from "../Screens/Subscription/Payment";
import Subscription from "../Screens/Subscription/Subscription";
import TermsAndConditions from "../Screens/TermsAndConditions/TermsAndConditions";
import Videoplayer from "../Screens/VideoPlayer/Videoplayer";
import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import LinkTvApp from "../Screens/LinkTvApp/LinkTvApp";
import TVAppIndex from "../Screens/LinkTvApp/TVAppIndex";

import PodcastModal from "../Components/Modals/PodcastModal";
import LivePlayer from "../Screens/LivePlayer/LivePlayer";
import WebViewFailed from "../Screens/PaymentStatus/PaymentFailed/WebViewFailed";
import WebViewSuccess from "../Screens/PaymentStatus/PaymentSuccess/WebViewSuccess";
import HowItWorks from "../Components/Modals/HowItWorks";
import LanguageModal from "../Components/Modals/LanguageModal";
import { affliateAnalytics, checkOperatingSystem } from "../utils/utils";
import AffliatePage from "../Screens/AffliatePage/AffliatePage";
import OpenApp from "../Components/OpenApp/OpenApp";
import LoginRequest from "../Screens/LoginRequest/LoginRequest";
import LiveChannels from "../Screens/LiveChannels/LiveChannels";
import EventDetailsScreen from "../Screens/EventDetails/EventDetailsScreen";
const LayoutsRoutes = () => {
  const location = useLocation();
  const [initialPage, setInitialPage] = useState("");
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const podcastModal = useSelector((state) => state?.podcastModal?.value);
  const howItWorksModal = useSelector((state) => state?.howItWorksModal?.value?.isOpen);
  const languageModal = useSelector(
    (state) => state?.languageModal?.value?.isOpen
  );
  const urlParams = new URLSearchParams(window.location.search);
  const affliateId = localStorage?.getItem("affliateId");
  const navigate = useNavigate();
  const [isOpenAppFeature, setIsOpenAppFeature] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);

  const androidToken = urlParams.get("antkn");
  const appInfo = {
    projectDetails: projectInfo,
  };
  useEffect(() => {
    if (!androidToken) {
      let openAppFeatureNotNeededRoutes = ["/privacy-policy", "/terms-and-conditions", "/subscription", "/payment", "success", "/failed", "/404"]

      if (
        (checkOperatingSystem() === "android" ||
          checkOperatingSystem() === "iPhone") &&
        !openAppFeatureNotNeededRoutes?.includes(location?.pathname)
        &&
        projectInfo?.projectConfig?.config?.DEEP_LINKING_ENABLED === "true"
      ) {
        setIsOpenAppFeature(true);
      }
    }
  }, [androidToken]);
  useEffect(() => {
    if (affliateId) {
      if (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone") {
        navigate("/");
      } else {
        setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);
        updateAffliateAnalytics()
      }
    } else {
      setInitialPage(projectInfo?.projectConfig?.config?.INITIAL_PAGE);
    }
  }, [affliateId]);

  useEffect(() => {
    if (urlParams.has('d') && urlParams.get('d') === 'm') {
      setHideHeaderFooter(true);
    } else {
      setHideHeaderFooter(false);
    }
  }, [urlParams]); 

  const updateAffliateAnalytics = async () => {
    try {
      const response = await affliateAnalytics(appInfo);
      if (response.status === 200) {
        localStorage.removeItem("affliateId");
        localStorage.removeItem("affliateUrl");
        navigate(location?.pathname)

      }
    } catch (err) {
      localStorage.removeItem("affliateId");
      localStorage.removeItem("affliateUrl");
    }
  }
  if (affliateId && (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone")) {
    return (
      <Routes>
        <Route path="/">
          <Route path="/" element={<Navigate to="/redirect" />} />
        </Route>
        <Route path="/redirect" element={<AffliatePage />} />
      </Routes>
    );
  } else {

    function RedirectToRegisterWithIOSProps() {
      const navigate = useNavigate();

      useEffect(() => {
        localStorage.setItem('iosSignup', true);
        // Redirect to /register with props iosSignup={true} after component is mounted
        navigate('/register', { state: { iosSignup: true } });
      }, [navigate]);

      // Navigate component must return null
      return null;
    }

    return (
      initialPage && (
        <div className="LayoutsContainer" >
          {location?.pathname !== "/login" &&
            location?.pathname !== "/register" &&
            location?.pathname !== "/ios-signup" &&
            location?.pathname !== "/forgot-password" &&
            location?.pathname !== "/forgot-password/reset" &&
            localStorage.getItem('iosSignup') != 'true' ? (
              !hideHeaderFooter && !androidToken && <Header/>
          ) : (
            <HeaderAccountsPage />
          )}

          {podcastModal?.isOpen && <PodcastModal data={podcastModal?.data} />}
          {howItWorksModal && projectInfo?.projectConfig?.pubid === 50030 && <HowItWorks />}
          {languageModal && <LanguageModal />}
          {isOpenAppFeature && (
            <OpenApp setIsOpenAppFeature={setIsOpenAppFeature} />
          )}
          <div className="routesContainer"
                style={{
                backgroundColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "var(--white-color)" 
              }} >
            {
              projectInfo?.projectConfig?.config?.GEO_BLOCKED === true &&
                !projectInfo?.projectConfig?.config?.GEO_UNBLOCKED_COUNTRIES.includes(projectInfo?.geoInfo.countryCode) ? (
                <GeoBlock />
              ) :
                (<Routes>
                  {/* for which page need to show initial */}
                  <Route path="/">
                    {initialPage === "LANDING" ? (
                      <Route path="/" element={<LandingScreeen />} />
                    ) : initialPage === "LOGIN" ? (
                      <Route path="/" element={<Navigate to="/login" />} />
                    ) : initialPage === "HOME" ? (
                      <Route path="/" element={<Navigate to="/home" />} />
                    ) : initialPage === "LIVE" ? (
                      projectInfo?.projectConfig?.config?.MULTI_CHANNELS_REQUIRED === "true" ? <Route path="/" element={<Navigate to="/live-channels" />} /> : <Route path="/" element={<Navigate to="/live" />} />
                    ) : null}
                  </Route>
                  <Route path="/subscription" element={<Subscription />} />

                  <Route element={<PublicRoutes />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/ios-signup" element={<RedirectToRegisterWithIOSProps />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route
                      path="/forgot-password/reset/:token"
                      element={<ResetPassword />}
                    />
                  </Route>

                  <Route path="/loginRequest" element={<LoginRequest />} />
                  <Route element={<PrivateRoutes />}>
                    <Route path="/home" element={localStorage.getItem('iosSignup') == 'true' ? <Navigate to="/subscription" /> : <Homepage />} />
                    <Route path="/movies/:name" element={<DetailsScreen />} />
                    <Route path="/category/:careers" element={<CommonPage />} />
                    <Route path="/search" element={<Search />} />
                 <Route path="/event/:eventId" element={<EventDetailsScreen />} />


                    <Route
                      path="/terms-and-conditions"
                      element={<TermsAndConditions />}
                    />
                    <Route path="/redirect" element={<Navigate to="/home" />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/live" element={<LivePlayer />} />
                    <Route path="/live-channels" element={<LiveChannels />} />

                    <Route path="/contact-support" element={<ContactSupport />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/news" element={<LatestNews />} />
                    <Route path="/news-info" element={<NewsDetails />} />
                    <Route path="/videos/:vanityUrl" element={<Videoplayer />} />                 
                  </Route>

                  <Route element={<ProtectedRoutes />}>
                    <Route path="/failed" element={<PaymentFailed />} />
                    <Route path="/success" element={<PaymentSuccess />} />
                    <Route path="/webviewfailed" element={<WebViewFailed />} />
                    <Route path="/webviewsuccess" element={<WebViewSuccess />} />

                    <Route path="/settings" element={<AccountSettings />} />
                    <Route
                      path="settings/billing-activity"
                      element={<BillingDetails />}
                    />
                    <Route
                      path="account/streaming-activity"
                      element={<StreamingActivity />}
                    />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/my-list" element={<MyList />} />
                    {/* <Route path="/tv" element={<LinkTvApp />} /> */}
                    <Route path="/tv" element={<TVAppIndex />} />
                  </Route>

                  <Route path="*" element={<Navigate to="/404" />}></Route>
                  <Route path="/404" element={<NotFound />} />
                </Routes>
                )}
          </div>
          {localStorage.getItem('iosSignup') != 'true' &&  !hideHeaderFooter && !androidToken &&  <Footer style={{ flexShrink: 0 }} />}
        </div>
      )
    );
  }
};

export default LayoutsRoutes;
