import logo from "./logo.svg";
import "./App.css";
import LayoutsRoutes from "./Routes/LayoutsRoutes";
import "swiper/css";
import "swiper/css/navigation";
import * as service from "./network/service";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProjectInfo } from "./Redux/ProjectInfo/ProjectInfoSlice";
import { getAccessToken } from "./Redux/AToken/ATokenSlice";
import Fingerprint2 from "fingerprintjs2";
import { checkOperatingSystem, getSessionId ,handleChangeColor} from "./utils/utils";
import { getUser } from "./Redux/UserSlice/UserSlice";
import { getUserDetails } from "./Redux/UserDetailsSlice/UserDetailsSlice";
import { getSessionIdValue } from "./Redux/SessionId/sessionIdSlice";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, SetIsAuthenticated] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const affliateId = urlParams.get("af_id");
  const loginTicket = urlParams.get("ticket");
  const androidToken = urlParams.get("antkn");
  const user = localStorage.getItem("userId");
  const qr = urlParams.get("qr");
  const appInfo = {
    projectDetails: projectInfo,
  };
  useEffect(() => {
    if (Object.keys(projectInfo)?.length > 0) {
      handleChangeColor(projectInfo?.projectConfig?.config);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (affliateId) {
      localStorage.setItem("affliateId", affliateId);
      localStorage.setItem("affliateUrl", window.location.href);
      if(checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone"){
        localStorage.setItem(
          "affliateRedirectPath",
         location?.pathname
        );
      }
    }
    let info = {};
    setLoading(true);
    if (user) {
      dispatch(
        getUser({
          user: user,
        })
      );
    }

    getAppInfo();
  }, []);

  useEffect(() => {
    if (Object.keys(projectInfo)?.length > 0) {
      if(loginTicket){
        externalWebsiteLogin()
      }
      else if (!localStorage.getItem("gid")) {
        getGuestUser();
      } else {
        getAuthentication();
      }
    }
  }, [projectInfo]);

  useEffect(() => {
    if (Object.keys(projectInfo)?.length > 0) {
      if (!localStorage.getItem("gid")) {
        getGuestUser();
      } else {
        getAuthentication();
      }
    }
  }, [projectInfo]);

  const getAppInfo = async () => {
    let info = {};
    setLoading(true);
    try {
      const appInfoResponse = await service.fetchAppInfo();
      if (appInfoResponse?.status === 200) {
        info.projectConfig = appInfoResponse?.data?.data;

        Fingerprint2.get(async (component) => {
          var values = component.map((component) => {
            return component.value;
          });
          var murmur = Fingerprint2.x64hash128(values.join(""), 31);

          info.device_id = murmur;
          const geoInfo = await getGeoInfo();
          info.geoInfo = geoInfo;
          dispatch(
            getProjectInfo({
              projectInfo: info,
            })
          );
          setProjectInfo(info);
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const externalWebsiteLogin = async () => {
    try{
      const response = await service.updateExternalUser(appInfo,loginTicket);
      if(response?.status === 200){
        localStorage.setItem("userId",response?.data?.data[0]?.user_id)
        dispatch(
          getUser({
            user:response?.data?.data[0]?.user_id
          })
        )
        await getAuthentication(response?.data?.data[0]?.user_id)
        
        if(qr){
          navigate(`/tv?code= ${qr}` );
        }
       
      }
    }catch(err){
       if (!localStorage.getItem("gid")) {
        getGuestUser();
      } else {
        getAuthentication();
      }
    }
  }

  const getGeoInfo = async () => {
    try {
      const geoInfoResponse = await service.fetchGeoInfo();
      if (geoInfoResponse?.status === 200) {
        return geoInfoResponse?.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const getGuestUser = async () => {
    try {
      const guestResponse = await service.guestUser(appInfo);
      if (guestResponse?.status === 200) {
        localStorage.setItem("gid", guestResponse?.data?.user_id);
        if (localStorage?.getItem("gid")) {
          getAuthentication(guestResponse?.data?.user_id);
        }
        const sessionId = await getSessionId(appInfo);
        dispatch(
          getSessionIdValue({
            sessionId: sessionId,
          })
        );
        const gid = guestResponse?.data?.user_id;
        updateDeviceAnalytics(gid, appInfo);


      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const getAuthentication = async (userId) => {
    try {
      const authenticationResponse = await service.authenticate(appInfo);
      if (
        authenticationResponse?.status === 200 &&
        authenticationResponse?.data?.message !== "Invalid user"
      ) {
        dispatch(
          getAccessToken({
            accessToken: authenticationResponse?.data?.token,
          })
        );
        dispatch(
          getUserDetails({
            userDetails: authenticationResponse?.data?.first_name,
          })
        );
        SetIsAuthenticated(true);
        setLoading(false);
        const sessionId = await getSessionId(appInfo);
        dispatch(
          getSessionIdValue({
            sessionId: sessionId,
          })
        );
        appInfo["sessionId"] = await sessionId;
        appInfo["accessToken"] = await authenticationResponse?.data?.token;
        if (!localStorage.getItem("applaunch")) {
          updateAppLaunch();
        }
        if (!localStorage.getItem("device_analytics")) {
          updateDeviceAnalytics(userId, appInfo);
        }
      }
      else{
        toast.error("Invalid User", {
          position: "bottom-center",
        });
      }
    } catch (err) {}
  };

  const updateAppLaunch = async () => {
    try {
      const response = await service.applaunchEvent("POP01", appInfo);
      if (response?.status === 200) {
        localStorage.setItem("applaunch", true);
      } else {
        localStorage.removeItem("applaunch");
      }
    } catch (err) {
      localStorage.removeItem("applaunch");
    }
  };

  const updateDeviceAnalytics = async (userId) => {
    try {
      const response = await service.analytics2(userId, appInfo);
      if (response?.status === 200) {
        localStorage.setItem("device_analytics", "true");
      } else {
        localStorage.removeItem("device_analytics");
      }
    } catch (err) {
      localStorage.removeItem("device_analytics");
    }
  };

  
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
      }}
    >
      {isAuthenticated && (
        <GoogleOAuthProvider clientId={projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID?projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID:"test"}>
        <LayoutsRoutes />
        </GoogleOAuthProvider>
      )}
    </div>
  );
}

export default App;
