import React, { useEffect, useState } from "react";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";

const Categories = ({ data,thumbnailOrientation ,type }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();

 

  return (
    <div className="categoriesContainer" 
         style={{borderBottomColor: projectInfo?.projectConfig?.config?.BACKGROUND_COLOR || "--border-color"  }}
     >
      <div className="wrapper">
      <div
          className="categoryNameContainerMedium"
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type},
            })
          }
        >
          <h1>{data?.category_name}</h1>
          </div>
        <div className="itemsContainer">
        <div
          className="categoryNameContainer"
          onClick={() =>
            navigate(`/category/${data?.key}`, {
              state: {careers:data?.key,type:data?.type},
            })
          }
        >
          <h1>{data?.category_name}</h1>
          <span className="seeAll">See all ({data?.shows?.length})</span>
        </div>
          <Swiper
            modules={[Navigation]}
            spaceBetween={1.5}
            slidesPerView={6}
            navigation={true}
            watchSlidesProgress
            loop={false}
            breakpoints={{
              320: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?2:1,
                spaceBetween: 2,
              },
              480:{
                slidesPerView: thumbnailOrientation=="PORTRAIT"?3:2,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: thumbnailOrientation=="PORTRAIT"?6:3,
                spaceBetween: 1.5,
              },
              980: {
                slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT?projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT:3,
                spaceBetween: 1.5,
              },
              1200: {
                slidesPerView:projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ?(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT) :thumbnailOrientation=="PORTRAIT"?6:5,
                spaceBetween: 1.5,
              },
             
            }}
          >
            {data?.shows?.map((item, index) => (
              <SwiperSlide key={index}>
                
                {data?.type!=="MAGAZINES" ?
                  (
                      <ShowCard
                      data={item}
                      season={false}
                      metaData={true}
                      type={data?.type}
                      key={data?.key}
                      
                    />
                  ):
                  (
                    <MagazineCard
                    data={item}
                    url={data.url}
                    metaData={true}
                    cardHover={true}
                    />
                  )  
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Categories;
